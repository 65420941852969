<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.capacity.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
        class="input-name"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="capacity-description rich-text-content mr-0 mt-1"
        v-html="$v.capacity.description.$model"
        @click="$bvModal.show(`edit-capacity-description-modal-${capacity.id}`)"
      ></div>
      <b-modal
        :id="`edit-capacity-description-modal-${capacity.id}`"
        title="Editar Descripción"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="capacity"
          :Text="capacity.description"
          :permit_blank="true"
          @save="patchCapacity"
          @close="
            $bvModal.hide(`edit-capacity-description-modal-${capacity.id}`)
          "
        ></NewRichTextEditor>
      </b-modal>
    </b-form-group>
    <b-form-group
      :label="$getVisibleNames('mesh.faculty', true, 'Facultades')"
      label-for="input-faculties"
      label-cols="0"
      label-cols-sm="3"
      class="sm"
    >
      <b-form-checkbox
        class="mt-2"
        v-model="$v.capacity.is_global.$model"
        @input="changeIsGlobal()"
        :value="true"
        :unchecked-value="false"
      >
        Es una
        {{ $getVisibleNames("mesh.capacity", false, "Capacidad") }}
        institucional
      </b-form-checkbox>
      <b-form-select
        v-if="facultiesFilter.length > 0 && !capacity.is_global"
        id="input-faculties"
        v-model="$v.capacity.faculties.$model"
        :options="facultiesFilter"
        value-field="id"
        text-field="name"
        :state="validateState('faculties')"
        aria-describedby="input-faculties-feedback"
        size="sm"
        multiple
        :select-size="
          facultiesFilter.length <= 5 ? facultiesFilter.length + 1 : 10
        "
      ></b-form-select>
      <div class="pt-2" v-else-if="facultiesFilter.length == 0">
        No cuenta con
        {{ $getVisibleNames("mesh.faculty", true, "Facultades") }}.
      </div>
      <b-form-invalid-feedback id="input-faculties-feedback"
        >Debe agregar la
        {{ $getVisibleNames("mesh.faculty", false, "Facultades") }} del
        {{
          $getVisibleNames("mesh.egressprofile", false, "Perfil de Egresos")
        }}.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="Capacity"></ModificationDate>
    </b-form-group>
    <div class="row mt-3">
      <div
        v-if="show_delete_button && !isNaN(this.capacity.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteCapacity"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0 mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CapacityForm",
  mixins: [validationMixin],
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    Capacity: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          is_global: false,
          faculties: [],
          updated_by: null,
          update_date: null,
        };
      },
    },
    faculty_id: { default: null },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      capacity: { ...this.Capacity },
    };
  },
  validations: {
    capacity: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {},
      faculties: {
        isValid() {
          if (this.faculty_id != null) {
            if (this.capacity.faculties.includes(this.faculty_id)) return true;
            else return false;
          }
          return true;
        },
      },
      is_global: {},
    },
  },
  computed: {
    ...mapGetters({
      faculties: names.FACULTIES,
    }),
    facultiesFilter() {
      let list = this.faculties;
      return list.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.capacity[name];
      return $dirty ? !$error : null;
    },
    changeIsGlobal() {
      if (this.capacity.is_global) {
        this.capacity.faculties = this.faculties.map((x) => x.id);
      }
    },
    patchCapacity(object, text) {
      this.capacity.description = text;
    },
    save() {
      this.$v.capacity.$touch();
      if (this.$v.capacity.$anyError) {
        return;
      }
      if (isNaN(this.capacity.id)) {
        this.createCapacity();
      } else this.updateCapacity();
    },
    createCapacity() {
      this.$store
        .dispatch(names.POST_CAPACITY, this.capacity)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("mesh.capacity", false, "Capacidad") +
                " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateCapacity() {
      this.$store
        .dispatch(names.UPDATE_CAPACITY, this.capacity)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("mesh.capacity", false, "Capacidad") +
                " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteCapacity() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.capacity",
          false,
          "Capacidad"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CAPACITY, this.capacity.id);
          this.$emit("deleted", this.capacity);
        }
      });
    },
  },
  created() {
    this.changeIsGlobal();
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.label {
  margin-top: 1%;
}
.input-name {
  margin-top: -1px;
}
#input-color {
  cursor: pointer;
}
.capacity-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  max-height: 10em;
  overflow-y: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.capacity-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>